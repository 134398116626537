<template>
  <div class="container-main-content">
    <div class="content-inner">
      <Header />
      <div class="content-inner__body">
        <h4 class="content-inner__body-title mb-4">Thông tin tài khoản</h4>
        <div class="content-inner__body-form">
          <div class="form-floating mb-4">
            <input
              v-model="dataUser.username"
              type="text"
              class="form-control"
              id="floatingUsername"
              placeholder="name@example.com"
              disabled
            />
            <label for="floatingUsername">Tên đăng nhập</label>
          </div>
          <div class="form-floating mb-4">
            <input
              v-model="dataUser.email"
              type="text"
              class="form-control"
              id="floatingEmail"
              placeholder="name@example.com"
              disabled
            />
            <label for="floatingEmail">Email</label>
          </div>
          <div class="form-floating mb-4">
            <input
              v-model="dataUser.phone"
              type="text"
              class="form-control"
              id="floatingPhone"
              placeholder="name@example.com"
              disabled
            />
            <label for="floatingPhone">Số điện thoại</label>
          </div>
        </div>
      </div>
      <p class="f14 text-second-color mb-4">
        <a
          href="update-account"
          class="link-primary-color"
          @click.prevent="handeUpdateAccount"
        >
          Cập nhật thông tin tài khoản
        </a>
      </p>
      <Footer />
    </div>

    <Loading
      :active="loading.isLoading"
      :can-cancel="true"
      :is-full-page="loading.fullPage"
      color="#3E69F6"
    >
    </Loading>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { UserService } from "../../services/user.service";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      token: this.$cookies.get("token"),
      currentQueries: window.location.href.includes("?")
        ? window.location.href.substring(window.location.href.indexOf("?"))
        : "",
      dataUser: {},
      loading: {
        isLoading: false,
        fullPage: true,
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        this.loading.isLoading = true;
        const response = await UserService.getUser(this.token);
        if (response.code === "SUCCESS") {
          this.dataUser = response.data;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading.isLoading = false;
      }
    },
    handeUpdateAccount() {
      this.$router.push({ path: `/update-account${this.currentQueries}` });
    },
  },
};
</script>
