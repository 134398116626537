import axios from "axios";
import { API_URL } from "../.env";

export class UserService {
  static async getUser(token) {
    try {
      const response = await axios({
        method: "get",
        url: `${API_URL}/user`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return error.response;
    }
  }

  static async createUser(params) {
    try {
      const response = await axios({
        method: "post",
        url: `${API_URL}/user`,
        data: params,
      });
      return response.data;
    } catch (error) {
      return error.response;
    }
  }

  static async updateUser(token, data) {
    try {
      const response = await axios({
        method: "patch",
        url: `${API_URL}/user`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data,
      });
      return response.data;
    } catch (error) {
      return error.response;
    }
  }

  static async deleteUser(token) {
    try {
      const response = await axios({
        method: "delete",
        url: `${API_URL}/user/delete-account`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return error.response;
    }
  }

  static async resendConfirmLink(userId) {
    try {
      const response = await axios({
        method: "post",
        url: `${API_URL}/user/send-verify-link`,
        data: {
          userId,
        },
      });
      return response.data;
    } catch (error) {
      return error.response;
    }
  }

  static async verifyPassword(token, data) {
    try {
      const response = await axios({
        method: "post",
        url: `${API_URL}/user/verify-pw`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data,
      });
      return response.data;
    } catch (error) {
      return error.response;
    }
  }

  static async changePassword(token, data) {
    try {
      const response = await axios({
        method: "post",
        url: `${API_URL}/user/change-pw`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data,
      });
      return response.data;
    } catch (error) {
      return error.response;
    }
  }

  static async forgetPassword(email) {
    try {
      const response = await axios({
        method: "post",
        url: `${API_URL}/password/forgot`,
        data: {
          email,
        },
      });
      return response.data;
    } catch (error) {
      return error.response;
    }
  }

  static async resetPassword(password) {
    try {
      const response = await axios({
        method: "post",
        url: `${API_URL}/password/forgot`,
        data: {
          password,
        },
      });
      return response.data;
    } catch (error) {
      return error.response;
    }
  }

  static async changePasswordByUrl(token, password) {
    try {
      const response = await axios({
        method: "post",
        url: `${API_URL}/password/change-by-url?token=${token}`,
        data: {
          password,
        },
      });
      return response.data;
    } catch (error) {
      return error.response;
    }
  }
}
